export function setCookie(cname, cvalue, exdays, isPlatformBrowser) {
    if (isPlatformBrowser) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}

export function getCookie(cname, isPlatformBrowser) {
    if (isPlatformBrowser) {
        let n = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(n) == 0) {
                return c.substring(n.length, c.length);
            }
        }
    }
    return null;
}

export function deleteCookie(cname, isPlatformBrowser) {
    if (isPlatformBrowser) {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";    
    }
}
